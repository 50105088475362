import {
  Flex,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Logo from '../../Assets/logo.png';

const Impresum = () => {
  const {t} = useTranslation();
  
  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={"500px"}
        bgColor={"#268bcc"}
      >
        <Flex
          height={"full"}
          width={"full"}
          flexDirection={"column"}
          justifyContent={"space-around"}
        >
          <Flex justifyContent={"center"}>
            <Text
              textAlign={"center"}
              px={100}
              py={50}
              bgColor={"white"}
              borderRadius={"2xl"}
              userSelect={"none"}
              color={"#268bcc"}
              width={"max-content"}
              fontSize={{ sm: "30px", md: "50px", lg: "60px" }}
              fontWeight={"bold"}
            >
              {t("Legal Notice")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection={"column"} p={20} gap={"20px"}>
        <Heading size={"md"} color={"#268bcc"}>
          {t("Legal Notice")}:
        </Heading>
        <UnorderedList
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
          color={"#268bcc"}
        >
          <ListItem>
            <Text>Name or company</Text>
            <Text>AkkoTech GmbH</Text>
          </ListItem>
          <ListItem>
            <Text>{t("Address")}:</Text>
            <Text>
              Weberstrasse 100
              <br />
              8400 Winterthur
            </Text>
          </ListItem>
          <ListItem>
            <Text>{t("Telephone")}:</Text>
            <Text>+41 78 851 86 89</Text>
          </ListItem>
          <ListItem>
            <Text>{t("Email Address")}:</Text>
            <Text>akkotech@bluewin.ch</Text>
          </ListItem>
        </UnorderedList>
        <Heading size={"md"} color={"#268bcc"}>
          {t("Legal Notice")}:
        </Heading>
        <Flex flexWrap={"wrap"} justifyContent={"space-between"} gap={'20px'}>
          <Text color={"#268bcc"} maxW={'lg'}>
            {t("This site uses cookies. Cookies are small text files that are stored permanently or temporarily on your computer when you visit this website. The purpose of the cookies is in particular to analyze the use of this website for statistical evaluation and for continuous improvements. You can completely or partially deactivate cookies in the settings of your browser at any time. If cookies are deactivated, you may no longer be able to use all the functions of this website.")}
          </Text>
          <Image display={"flex"} alignSelf={"end"} mixBlendMode={"multiply"} height={["80px"]} src={Logo} alt='logo'/>
        </Flex>
      </Flex>
    </>
  );
};

export default Impresum;
