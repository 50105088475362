import { EmailIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";

const Contact = () => {
  const { t } = useTranslation();
  const [recaptchaValidated, setRecaptchaValidated] = useState(true);

  const onRecaptchaChange = () => setRecaptchaValidated(false)

  function validateName(value) {
    let error;
    if (!value) {
      error = t("Name is required");
    }
    return error;
  }
  function validateEmail(value) {
    let error;
    if (!value) {
      error = t("E-mail is required");
    }
    return error;
  }
  function validateText(value) {
    let error;
    if (!value) {
      error = t("Text is required");
    }
    return error;
  }

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const onSubmit = (values, submitProps) =>{
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values })
    })
      .then(resposne =>{
        if(!resposne.ok){
          throw new Error(resposne.status)
        }else if(resposne.ok){
          alert("Success!")
          submitProps.resetForm()
        }else{
          alert("Something went wrong!")
        }
        return resposne
      })
      .catch(error => alert(error));
  }


  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={"500px"}
        bgColor={"#268bcc"}
      >
        <Flex
          height={"full"}
          width={"full"}
          flexDirection={"column"}
          justifyContent={"space-around"}
        >
          <Flex justifyContent={"center"}>
            <Text
              textAlign={"center"}
              px={100}
              py={50}
              bgColor={"white"}
              borderRadius={"2xl"}
              userSelect={"none"}
              color={"#268bcc"}
              width={"max-content"}
              fontSize={{ sm: "30px", md: "50px", lg: "60px" }}
              fontWeight={"bold"}
            >
              {t("Contact")}
            </Text>
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Text
              userSelect={"none"}
              color={"white"}
              fontSize={"20px"}
              textAlign={"center"}
            >
              {t(
                "Here you will find our contact details. Contact us without obligation. We are happy to hear from you."
              )}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent={"center"}>
        <Grid
          templateColumns={{
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 2fr)",
            lg: "repeat(2, 2fr)",
          }}
          gap={"100px"}
          px={"20px"}
          py={"20px"}
        >
          <GridItem
            w="100%"
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
            width={"100%"}
            color={"#268bcc"}
          >
            <Text fontSize={"xl"} fontWeight={"bold"}>
              Akkotech GmbH
            </Text>
            <Text fontSize={"lg"}>
              Akkotech GmbH
              <br />
              Weberstrasse 100
              <br />
              8400 Winterthur
            </Text>
            <Flex gap={"10px"}>
              <Text>{t("Telephone")}:</Text>
              <Text>+41 78 851 86 89</Text>
            </Flex>
            <Flex gap={"10px"}>
              <Text>{t("Email Address")}:</Text>
              <Text>akkotech@bluewin.ch</Text>
            </Flex>
          </GridItem>
          <GridItem w="100%" color={"#268bcc"}>
            <Formik
              initialValues={{ name: "", email: "", text: "" }}
              onSubmit={onSubmit}
            >
              {(props) => (
                <Form>
                  <input type="hidden" name="form-name" value="contact" />
                  <Stack spacing={3}>
                    <Field name="name" validate={validateName}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          isRequired
                        >
                          <FormLabel>Name</FormLabel>
                          <Input {...field} type="text" variant="filled" />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="email" validate={validateEmail}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                          isRequired
                        >
                          <FormLabel>Email</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<EmailIcon color="gray.300" />}
                            />
                            <Input {...field} type="email" variant="filled" />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="text" validate={validateText}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.text && form.touched.text}
                          isRequired
                        >
                          <FormLabel>{t("Message")}</FormLabel>
                          <Textarea {...field} variant="filled" type="text" />
                          <FormErrorMessage>
                            {form.errors.text}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <ReCAPTCHA
                      sitekey="6LfQG6kkAAAAAMIBW0_M3pk9Bs0MGEMccXe8a1H0"
                      onChange={onRecaptchaChange}
                    />
                    <Button
                      isDisabled={recaptchaValidated}
                      colorScheme="linkedin"
                      variant="solid"
                      type="submit"
                    >
                      {t("Send")}
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </GridItem>
        </Grid>
      </Flex>
      <iframe
        title="location"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d312.927388887102!2d8.746051760884232!3d47.49000653361084!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a999570b01e15%3A0xeb12e211b04d0bc4!2sWeberstrasse%20100%2C%208400%20Winterthur%2C%20Switzerland!5e0!3m2!1sen!2smk!4v1677157624045!5m2!1sen!2smk"
        width="100%"
        height="500px"
        style={{ border: "0" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
};

export default Contact;
