import {
  Button,
  Flex,
  Grid,
  Img,
  Text,
} from "@chakra-ui/react";
// import SanityBlockContent from "@sanity/block-content-to-react";
import React, { useEffect, useMemo, useState } from "react";
import sanityClient from "../../client";
import { getImageDimensions } from "@sanity/asset-utils";
import { Gallery, Item } from "react-photoswipe-gallery";
import 'photoswipe/dist/photoswipe.css'
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const References = () => {
  const [galleryData, setGalleryData] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'gallery']{
          _id,
          title,
          "images": images[].asset->url | [0...5],
          "totalImages": count(images[].asset->url)
        }`
      )
      .then((data) => {
        const modifiedData = data.map((data)=>{
          return {...data, start: 0, end: 5}
        })
        setGalleryData(modifiedData)
      })
      .catch(console.error);
  }, []);

  const handleNext = (dataProp) => {
    const { totalImages, _id, start, end } = dataProp;

    if (end !== totalImages) {
      sanityClient
        .fetch(
          `*[_type == 'gallery' && _id == '${_id}']{
            _id,
            title,
            "images": images[].asset->url | [${start + 5}...${end + 5}],
            "totalImages": count(images[].asset->url)
          }`
        )
        .then((data) => {
          setGalleryData((prevState) =>
            prevState.map((item) => {
              if (item._id === data[0]._id) {
                return { ...data[0], start: start + 5, end: end + 5 };
              }
              return item;
            })
          );
        })
        .catch(console.error);
    }
  };

  const handlePrev = (dataProp) => {
    const {_id, start, end} = dataProp
    if(start !== 0){
      sanityClient
        .fetch(
          `*[_type == 'gallery' && _id == '${_id}']{
            _id,
            title,
            "images": images[].asset->url | [${start-5}...${end-5}],
            "totalImages": count(images[].asset->url)
          }`
        )
      .then((data) => {
        setGalleryData((prevState) =>
          prevState.map((item) => {
            if (item._id === data[0]._id) {
              return { ...data[0], start: start - 5, end: end - 5 };
            }
            return item;
          })
        );
      })
        .catch(console.error);
    }
  }

  const datas = useMemo(() => {
    return galleryData.map((data) => {
      const img = data?.images.map((img) => {
        const { height, width } = getImageDimensions({
          url: img,
        });
        return { 
          src: img, 
          height: height, 
          width: width,
        };
      });
      return { 
        ...data,
        images: img
      }
    });
  }, [galleryData]);

  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={"500px"}
        bgColor={"#268bcc"}
      >
        <Flex
          height={"full"}
          flexDirection={"column"}
          justifyContent={"space-around"}
        >
          <Flex justifyContent={"center"}>
            <Text
              textAlign={"center"}
              px={100}
              py={50}
              bgColor={"white"}
              borderRadius={"2xl"}
              userSelect={"none"}
              color={"#268bcc"}
              width={"max-content"}
              fontSize={{ sm: "30px", md: "50px", lg: "60px" }}
              fontWeight={"bold"}
            >
              {t("Credentials")}
            </Text>
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Text
              userSelect={"none"}
              color={"white"}
              fontSize={"20px"}
              textAlign={"center"}
            >
              {t("Find out more about our company")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {datas.map((data, idx) => {
        return (
          <>
            <motion.div
              initial={{ y: +30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
            <Gallery key={idx}>
              <Grid
                justifyContent={"center"}
                templateColumns={`repeat(auto-fill, 300px)`}
                gap={"30px"}
                my={"20px"}
              >
                {data?.images.map((img, idx) => {
                  return (
                      <Item
                        key={idx}
                        original={img?.src}
                        width={`${img?.width}`}
                        height={`${img?.height}`}
                      >
                        {({ ref, open }) => (
                          <Img
                            cursor={"pointer"}
                            borderRadius={"lg"}
                            height={"200px"}
                            width={"100%"}
                            objectFit={"cover"}
                            ref={ref}
                            onClick={open}
                            src={img?.src}
                          />
                        )}
                      </Item>
                  );
                })}
              </Grid>
            </Gallery>
            </motion.div>
            <motion.div
              initial={{ y: +30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              <Flex justifyContent={"center"} gap={"30px"} my={"20px"}>
                <Button
                  leftIcon={<ArrowBackIcon />}
                  variant={"outline"}
                  colorScheme="telegram"
                  onClick={() => handlePrev(data)}
                >
                  {t("Prev")}
                </Button>
                <Button
                  rightIcon={<ArrowForwardIcon />}
                  colorScheme="telegram"
                  onClick={() => handleNext(data)}
                >
                  {t("Next")}
                </Button>
              </Flex>
            </motion.div>
          </>
        );
      })}
    </>
  );
};

export default References;
