import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

const NotFound = () => {
  return (
    <Flex height={"80vh"} flexDir={'column'} justifyContent={'center'} alignItems={'center'}>
      <Text m={0} p={0} fontSize={["50", "100px", "200px"]} fontWeight="light" color="lightgray">OOOPS!</Text>
      <Text m={0} p={0} fontSize={['xl','xl',"2xl"]} fontWeight={"bold"} bgColor="white">404 - THE PAGE CAN NOT FOUND</Text>
    </Flex>
  )
}

export default NotFound