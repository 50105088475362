import './App.css';
import { BrowserRouter as Browser, Route, Routes } from 'react-router-dom';

import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

import AboutUs from './Pages/AboutUs/AboutUs';
import Activity from './Pages/Activity/Activity';
import Contact from './Pages/Contact/Contact';
import Home from './Pages/Home/Home';
// import Machines from './Pages/Machines/Machines';
import References from './Pages/References/References';
import NotFound from './Pages/NotFound/NotFound'
import { Box } from '@chakra-ui/react';
import Impressum from './Pages/Impressum/Impressum';

function App() {
  return (
    <Browser>
      <Box minH={"100vh"} display={"flex"} flexDirection={"column"}>
        <Navbar />
        <Box flexGrow={1}>
          <Routes>
            <Route path='*' element={<NotFound />}/>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/activity" element={<Activity />} />
            {/* <Route path="/machinery" element={<Machines />} /> */}
            <Route path="/references" element={<References />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/impressum" element={<Impressum />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Browser>
  );
}

export default App;
