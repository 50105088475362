import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";

import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        debug: false,
        fallbackLng: 'en',
        lng: 'de',
        backend: {
            backends: [LocalStorageBackend, HttpBackend],
            backendOptions: [
                {
                expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
                },
                {
                loadPath: "/locales/{{lng}}/{{ns}}.json",
                },
            ],
            },
    })