import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import Logo from '../Assets/logo.png';

import {
  ChevronRightIcon,
  HamburgerIcon,
  // MoonIcon,
  // SunIcon,
} from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Select,
  Show,
  Text,
  // useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));

  const navigationLinks = [
    { name: "Home", href: "/", active: true },
    { name: "About us", href: "/about-us", active: false },
    { name: "Our field of activity", href: "/activity", active: false },
    // { name: "Our machinery", href: "/machinery", active: false },
    { name: "Credentials", href: "/references", active: false },
    { name: "Contact", href: "/contact", active: false },
  ];

  const [navigation, setNavigation] = useState(navigationLinks);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const btnRef = useRef();

  useEffect(() => {
    setNavigation((prevState) => {
      return prevState.map((obj) => {
        if (obj.href === location.pathname) {
          return { ...obj, active: true };
        } else {
          return { ...obj, active: false };
        }
      });
    });
  }, [location.pathname]);

  const handleLanguageChange = (value) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  // const {colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      px={"20px"}
      py={"20px"}
      bg="#268bcc"
    >
      <Flex>
        <Link to={"/"}>
          <Image mixBlendMode={"multiply"} height={["50px", "50px", "80px"]} src={Logo} alt='logo'/>
        </Link>
      </Flex>
      <Flex>
        <Show above="1080px">
          <Flex justifyContent={"space-between"} cursor={"pointer"} gap={'5px'}>
            {navigation.map((navLink, index) => {
              return (
                <Link key={index} to={navLink.href}>
                  <Button
                    key={index}
                    borderBottom={navLink.active ? "2px" : "none"}
                    bgColor={"transparent"}
                    color={"white"}
                    textAlign={"center"}
                    _hover={{ bgColor: "white", color: "black" }}
                  >
                    {t(navLink.name)}
                  </Button>
                </Link>
              );
            })}
            <Select
              // bgColor={"white"}
              onChange={(e) => handleLanguageChange(e.target.value)}
              value={language}
              variant={"filled"}
            >
              <option value="de">Deutsch</option>
              <option value="en">English</option>
            </Select>
            {/* <Button onClick={toggleColorMode}>
              {
                colorMode === 'light' ? <MoonIcon/> : <SunIcon/>
              }
            </Button> */}
          </Flex>
        </Show>
        <Show below="1079px">
          <Flex gap={"10px"}>
            <Select
              bgColor={"white"}
              onChange={(e) => handleLanguageChange(e.target.value)}
              value={language}
            >
              <option value="de">Deutsch</option>
              <option value="en">English</option>
            </Select>
            <Box
              ref={btnRef}
              onClick={onOpen}
              padding={"5px"}
              border={"2px"}
              borderColor={"white"}
              cursor={"pointer"}
              borderRadius={"md"}
            >
              <Icon as={HamburgerIcon} boxSize={6} color={"white"} />
            </Box>
          </Flex>
          <Drawer
            isOpen={isOpen}
            placement="top"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>
                <Link to={"/"}>
                  <Image mixBlendMode={"multiply"} height={["50px", "50px", "80px"]} src={Logo} alt='logo'/>
                </Link>
              </DrawerHeader>
              <Divider borderColor={"#268bcc"} />
              <DrawerBody>
                {navigation.map((navLink, index) => {
                  return (
                    <Box
                      key={index}
                      onClick={onClose}
                      w="100%"
                      py={"10px"}
                      cursor={"pointer"}
                    >
                      <Link to={navLink.href}>
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Text fontWeight={"medium"} color={"#268bcc"}>
                            {t(navLink.name)}
                          </Text>
                          <Icon as={ChevronRightIcon} color={"#268bcc"} />
                        </Flex>
                      </Link>
                      {navigation.length - 1 !== index && (
                        <Divider borderColor={"#268bcc"} />
                      )}
                    </Box>
                  );
                })}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Show>
      </Flex>
    </Flex>
  );
};

export default Navbar;
