import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react'
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const {t} = useTranslation();
  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={"500px"}
        bgColor={"#268bcc"}
      >
        <Flex
          height={"full"}
          width={"full"}
          flexDirection={"column"}
          justifyContent={"space-around"}
        >
          <Flex justifyContent={'center'}>
            <Text
              textAlign={"center"}
              px={100}
              py={50}
              bgColor={"white"}
              borderRadius={"2xl"}
              userSelect={"none"}
              color={"#268bcc"}
              width={"max-content"}
              fontSize={{ sm: "30px", md: "50px", lg: "60px" }}
              fontWeight={"bold"}
            >
              {t("About")} AkkoTech GmbH
            </Text>
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Text
              userSelect={"none"}
              color={"white"}
              fontSize={"20px"}
              textAlign={"center"}
            >
              {t("Find out more about our company")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Box px={"20px"} py={"20px"} textAlign={"center"}>
        <Text py={"20px"} fontWeight={"bold"} fontSize={"30px"}>
          AkkoTech GmbH.
        </Text>
        <Text mx={["20px", "20px", "50px", "220px"]}>
          {t("The purpose of the company Akkotech GmbH in Winterthur is as follows. The company aims to provide services and work in the construction and related trades, in particular in the areas of plastering and painting, masonry and formwork work, as well as trading in goods of all kinds. The company can establish branches and subsidiaries at home and abroad and deal with others Involve companies at home and abroad and carry out all transactions that are directly or indirectly related to their purpose. The company can acquire, encumber, sell and manage company properties in Germany and abroad. It can also provide financing for its own or third-party account, as well as provide guarantees and sureties for subsidiaries and third parties.")}
        </Text>
      </Box>
    </>
  );
}

export default AboutUs