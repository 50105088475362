import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import Cover1 from "../../Assets/cover1.jpg";
import Cover2 from "../../Assets/cover2.jpg";
import { Icon, StarIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import sanityClient from '../../client';
import { useTranslation } from "react-i18next";

const Home = () => {
  const [post, setPost] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'post']{
          title
      }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);
  
  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={"700px"}
        bgColor={"#268bcc"}
      >
        <Flex
          height={"full"}
          flexDirection={"column"}
          justifyContent={"space-around"}
        >
          <Flex flexDir={"column"} alignItems={"center"} justifyContent={"center"} gap={'20px'}>
            <Text
              userSelect={"none"}
              color={"white"}
              fontSize={"40px"}
              fontWeight={"light"}
              textAlign="center"
            >
              {t("Build your construction projects with")}
            </Text>
            <Text
              userSelect={"none"}
              color={"white"}
              fontSize={["40px", "60px"]}
              fontWeight={"bold"}
              textDecoration={"underline"}
            >
              AkkoTech GmbH
            </Text>
            <Flex flexDir={['column', 'row']} alignItems={'center'} gap={'10px'}>
              <Image
                  mx={"10px"}
                  borderRadius={['3xl',"full"]}
                  objectFit={"cover"}
                  boxSize={["120px", "100px", '150px', '200px']}
                  width={["90%", "300px", "300px", "450px"]}
                  src={Cover1}
                  draggable={"false"}
                />
                <Image
                  mx={"10px"}
                  borderRadius={['3xl',"full"]}
                  objectFit={"cover"}
                  boxSize={["120px", "100px", '150px', '200px']}
                  width={["90%", "300px", "300px", "450px"]}
                  src={Cover2}
                  draggable={"false"}
                />
            </Flex>
          </Flex>
            <Text
              mx={[10, 15, 35, 50]}
              color={"white"}
              fontSize={'30px'}
              textAlign={"center"}
            >
              {t("Your partner for building construction and conversion")}
            </Text>
          <Flex width={"100%"} flexDir={'column'} justifyContent={"center"} alignItems={"center"}>
            <a href="tel:+41788518689">
              <Button>+41 78 851 86 89</Button>
            </a>
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent={"center"} px={"20px"} pt={"20px"}>
        <Heading size={"md"} color={"#268bcc"}>
          {t("Current construction sites")}:
        </Heading>
      </Flex>
      <Grid
        justifyContent={"center"}
        templateColumns={`repeat(auto-fill, 300px)`}
        gap={6}
        px={"20px"}
        py={"20px"}
      >
        {post.map((p, idx) => {
          return (
            <GridItem key={idx}>
              <Card
                py={5}
                justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
                width={"100%"}
                variant={"filled"}
              >
                <Flex gap={"10px"}>
                  <Icon as={StarIcon} boxSize={6} />
                  <Heading size="md">{p.title}</Heading>
                </Flex>
              </Card>
            </GridItem>
          );
        })}
      </Grid>

      <Divider borderColor={"#268bcc"} my={10} />
      <Flex justifyContent={"center"}>
        <Grid
          templateColumns={{
            sm: "repeat(1, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={"10px"}
          mx={"40px"}
          my={"40px"}
          maxW={"6xl"}
        >
          <GridItem>
            <Card
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={"2xs"}
              variant={"outlined"}
              borderRadius={"2xl"}
              borderWidth={"1px"}
              borderColor={"#268bcc"}
            >
              <CardHeader>
                <Stack mt="6" spacing="3" textAlign={"center"}>
                  <Heading size="md">{t('Our field of activity')}</Heading>
                  <Text>{t("Here you will find a list of our services")}.</Text>
                </Stack>
              </CardHeader>
              <CardBody
                display={"flex"}
                justifyContent={"center"}
                alignItems={"end"}
              >
                <Link to={"/activity"}>
                  <Button
                    variant={"filled"}
                    bgColor={"#268bcc"}
                    color={"white"}
                  >
                    {t("More Info")}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem>
            <Card
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={"2xs"}
              variant={"outlined"}
              borderRadius={"2xl"}
              borderWidth={"1px"}
              borderColor={"#268bcc"}
            >
              <CardHeader>
                <Stack mt="6" spacing="3" textAlign={"center"}>
                  <Heading size="md">{t("Credentials")}</Heading>
                  <Text>{t("Take a look at a selection of our reference work")}.</Text>
                </Stack>
              </CardHeader>
              <CardBody
                display={"flex"}
                justifyContent={"center"}
                alignItems={"end"}
              >
                <Link to="/references">
                  <Button
                    variant={"filled"}
                    bgColor={"#268bcc"}
                    color={"white"}
                  >
                    {t("More Info")}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem>
            <Card
              display={"flex"}
              flexDir={"column"}
              justifyContent={"space-between"}
              height={"2xs"}
              variant={"outlined"}
              borderRadius={"2xl"}
              borderWidth={"1px"}
              borderColor={"#268bcc"}
            >
              <CardHeader>
                <Stack mt="6" spacing="3" textAlign={"center"}>
                  <Heading size="md">{t("Contact")}</Heading>
                  <Text>
                    {t("Contact us. We would be happy to advise you – competently and without obligation")}!
                  </Text>
                </Stack>
              </CardHeader>
              <CardBody
                display={"flex"}
                justifyContent={"center"}
                alignItems={"end"}
              >
                <Link to="/contact">
                  <Button
                    variant={"filled"}
                    bgColor={"#268bcc"}
                    color={"white"}
                  >
                    {t("More Info")}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </Flex>
    </>
  );
};

export default Home;
