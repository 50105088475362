import { AspectRatio, Box, Flex, Grid, GridItem, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import sanityClient from "../../client";
import SanityBlockContent from "@sanity/block-content-to-react";
import { useTranslation } from "react-i18next";

const Activity = () => {
  const [activityData, setActivityData] = useState();
  const {t} = useTranslation();
  const localStorageLanguage = localStorage.getItem("i18nextLng") 

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'activity']{
          'title': title['${localStorageLanguage}'],
          poster{
            asset->{
              _id,
              url
            }
          },
          'content': content['${localStorageLanguage}']
      }`
      )
      .then((data) => setActivityData(data))
      .catch(console.error);
  }, [localStorageLanguage]);
  
  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={"500px"}
        bgColor={"#268bcc"}
      >
        <Flex
          height={"full"}
          flexDirection={"column"}
          justifyContent={"space-around"}
        >
          <Flex justifyContent={"center"}>
            <Text
              textAlign={"center"}
              px={100}
              py={50}
              bgColor={"white"}
              borderRadius={"2xl"}
              userSelect={"none"}
              color={"#268bcc"}
              width={"max-content"}
              fontSize={{ sm: "30px", md: "50px", lg: "60px" }}
              fontWeight={"bold"}
            >
              {t("Our field of activity")}
            </Text>
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Text
              userSelect={"none"}
              color={"white"}
              fontSize={"20px"}
              textAlign={"center"}
            >
              {t("Find out more about our company")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {activityData &&
        activityData.map((activity, index) => {
          return (
            <Box key={index} px={"20px"} py={"20px"}>
              <Grid
                templateColumns={{
                  sm: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(2, 1fr)",
                }}
                gap={15}
              >
                <GridItem
                  display={"flex"}
                  justifyContent={["center", "center", "flex-end"]}
                >
                  <Box>
                    <AspectRatio boxSize={["200px", "md"]} ratio={1 / 1}>
                      <Image
                        objectFit={"cover"}
                        src={activity?.poster?.asset?.url}
                      />
                    </AspectRatio>
                  </Box>
                </GridItem>
                <GridItem display={"flex"} justifyContent={"start"}>
                  <Box marginX={10}>
                    <Heading size={"md"}>{activity.title}</Heading>
                    <Box marginTop={10}>
                      <SanityBlockContent
                        blocks={activity.content}
                        projectId="s2fd2ah6"
                        dataset="akkotech-dataset"
                      />
                    </Box>
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          );
        })}
    </>
  );
};

export default Activity;
