import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const {t} = useTranslation();
  return (
    <Flex justifyContent={'space-between'} alignItems={'center'} bg={"#268bcc"} p={"20px"}>
      <Text color={"white"} fontSize={"xl"}>
        &copy; {t("All right reserved")} - AkkoTech GmbH
      </Text>
      <Link to="/impressum">
        <Text color={'white'}>{t("Legal Notice")}</Text>
      </Link>
    </Flex>
  );
};

export default Footer;
